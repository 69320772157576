
import Vue from 'vue'
import CategoryMainView from '../../views/category/CategoryMain.vue'
import { mapActions } from 'vuex'
export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'CategoryMain',
  components: { CategoryMainView },
  created() {
    this.fetchCategoryMain()
  },
  methods: {
    ...mapActions('CategoryStore', ['fetchCategoryMain']),
  },
})

interface Data {}
interface Methods {
  fetchCategoryMain(): void
}
interface Computed {}
interface Props {}
